import { BASE_URL_V1, BASE_WP_URL } from '../../../static/config';
import { getTokenFromCookie } from '../../../api/index';
import axios from 'axios';

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${getTokenFromCookie()}`,
    },
  };
};

export const API_CREATE_SESSION = (fileId, temporary, paid) =>
  axios
    .post(
      temporary ? `${BASE_URL_V1}/editor/temporary` : `${BASE_URL_V1}/editor`,
      temporary
        ? {
            fileId,
            paid,
          }
        : {
            fileId: fileId,
          },
      headers(),
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_START_SAVE_PROCESSING = (sessionId, body, temporary) => {
  return axios
    .post(
      temporary
        ? `${BASE_URL_V1}/editor/${sessionId}/save/temporary`
        : `${BASE_URL_V1}/editor/${sessionId}/save`,
      body,
      headers(),
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const API_GET_PROCESSING_STATUS = (sessionId, temporary) =>
  axios
    .get(
      temporary
        ? `${BASE_URL_V1}/editor/${sessionId}/temporary`
        : `${BASE_URL_V1}/editor/${sessionId}`,
      headers(),
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_APPLY_PAGE_SETTINGS = (sessionId, body, temporary) =>
  axios
    .post(
      temporary
        ? `${BASE_URL_V1}/editor/${sessionId}/settings/apply/temporary`
        : `${BASE_URL_V1}/editor/${sessionId}/settings/apply`,
      body,
      headers(),
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_GET_FILE_INFORMATION = (sessionId, temporary) =>
  axios
    .get(
      temporary
        ? `${BASE_URL_V1}/editor/${sessionId}/pdf/url/temporary`
        : `${BASE_URL_V1}/editor/${sessionId}/pdf/url`,
      headers(),
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_UPLOAD_IMAGE_FOR_IMAGE_TOOL = (data, temporary) => {
  const formData = new FormData();
  formData.append('file', data);

  return axios
    .post(
      temporary
        ? `${BASE_URL_V1}/editor/image/temporary`
        : `${BASE_URL_V1}/editor/image`,
      formData,
      headers(),
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const API_UPLOAD_FILE_FROM_WP = body =>
  axios
    .post(`${BASE_URL_V1}/file/upload/wp/temporary`, body, headers())
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_GET_DOCUMENT_PAID_INFORMATION = id =>
  axios
    .get(`${BASE_WP_URL}/is-paid/${id}`, headers())
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_FINALIZE_PURCHASE_BY_SESSION_ID = sessionId =>
  axios
    .post(`${BASE_URL_V1}/editor/${sessionId}/purchase`, {}, headers())
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_FINALIZE_PURCHASE_BY_FILE_ID = fileId =>
  axios
    .post(`${BASE_URL_V1}/editor/purchase/file/${fileId}`, {}, headers())
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_GET_USER_BILLING_PLANS = () =>
  axios
    .get(`${BASE_URL_V1}/plan/web`, headers())
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_GET_PUBLIC_BILLING_PLANS = () =>
  axios
    .get(`${BASE_URL_V1}/plan/public`, headers())
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_CREATE_PURCHASE = uuid =>
  axios
    .post(
      `${BASE_URL_V1}/stripe/purchase`,
      {
        idempotencyKey: uuid,
      },
      headers(),
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_CREATE_PURCHASE_PUBLIC = body =>
  axios
    .post(`${BASE_URL_V1}/stripe/purchase/public`, body, headers())
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_ASSIGN_FILE_TO_USER = sessionId =>
  axios
    .post(`${BASE_URL_V1}/editor/${sessionId}/assign`, {}, headers())
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_GET_INTERACTIVE_FIELDS_TOOLTIP = (sessionId, temporary) =>
  axios
    .get(
      temporary
        ? `${BASE_URL_V1}/editor/${sessionId}/pdf/form/temporary`
        : `${BASE_URL_V1}/editor/${sessionId}/pdf/form`,
      headers(),
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_GET_SESSION_CONTENT = (sessionId, temporary) =>
  axios
    .get(
      temporary
        ? `${BASE_URL_V1}/editor/${sessionId}/temporary`
        : `${BASE_URL_V1}/editor/${sessionId}`,
      headers(),
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_SUBMIT_RATE = (sessionId, body) =>
  axios
    .post(`${BASE_URL_V1}/editor/${sessionId}/rate`, body, headers())
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_GET_RATE = () =>
  axios
    .get(`${BASE_URL_V1}/editor/rating/stats`, headers())
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_GET_SIGNATURES = type =>
  axios
    .get(`${BASE_URL_V1}/signature-request/image?type=${type}`, headers())
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_SUBMIT_SIGNATURE = body =>
  axios
    .post(`${BASE_URL_V1}/signature-request/image/upload`, body, headers())
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_DELETE_SIGNATURE = sigId =>
  axios
    .delete(`${BASE_URL_V1}/signature-request/image/${sigId}`, headers())
    .then(response => response?.data)
    .catch(error => error?.response?.data);

export const API_REGISTER_EMAIL = (sessionId, body) =>
  axios
    .post(`${BASE_URL_V1}/user/${sessionId}/rate`, body, headers())
    .then(response => response?.data)
    .catch(error => error?.response?.data);
