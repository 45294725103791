import { api, getTokenFromCookie } from './index';
import { BASE_URL_V2 } from '../static/config';
import axios from 'axios';

/**
 * @typedef {Object} SubscriberType
 * @property {string} email
 * @property {string} fullName
 * @property {('admin'|'upload'|'view')} permission
 */

export const getUserSubscribers = () => {
  return api.get({
    path: '/user/subscriber',
  });
};

/**
 * @param {SubscriberType} subscriber
 */
export const addSubscriber = subscriber => {
  return api.post({
    path: '/user/subscriber',
    body: subscriber,
  });
};

export const removeSubscriber = subscriberId => {
  return api.delete({
    path: `/user/subscriber/${subscriberId}`,
  });
};

export const updateSubscriberPermission = (subscriberId, permission) => {
  return api.put({
    path: `/user/subscriber/${subscriberId}`,
    body: { permission },
  });
};

export const getAccountsList = () => {
  return api.get({
    path: '/user/account',
  });
};

export const switchAccount = accountId => {
  return api.post({
    path: '/user/account/switch',
    body: { accountId },
  });
};

export const makePersonal = () => {
  return api.post({
    path: '/user/personal',
  });
};

export const makeBusiness = () => {
  return api.post({
    path: '/user/business',
  });
};

export const sendVerificationCode = email => {
  // return api.post({
  //   path: '/user/verification',
  //   body: { email },
  // });

  return axios
    .post(
      `${BASE_URL_V2}/user/verification`,
      {
        email,
      },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`,
        },
      },
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const checkVerificationCode = body => {
  return api.post({
    path: '/user/verification/check',
    body,
  });
};

export const checkActivationCode = body => {
  return api.post({
    path: '/user/activate/code',
    body,
  });
};

export const verifyPassword = password => {
  return api.post({
    path: `/user/password/verify`,
    body: {
      password,
    },
  });
};

export const acceptLicenseInvitePublic = token => {
  return api.post({
    path: `/license/invite/accept/public?token=${token}`,
  });
};

export const rejectLicenseInvitePublic = token => {
  return api.post({
    path: `/license/invite/cancel/public?token=${token}`,
  });
};

/**
 * @param email {string, required} : email of the user
 * @description Register not completed user on the website.
 * Will register only in case if user is absent in db.
 * In case if there is already existing not completed user, will do nothing,
 * just return the token, which can be used in the future for some public requests
 */
export const createNonCompletedUser = email => {
  return api.post({
    path: '/user/not-completed',
    body: { email },
  });
};
