export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const LIBRARY_URL = process.env.REACT_APP_LIBRARY_URL;
export const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;

export const BASE_URL_V1 = SERVER_URL + '/api/v1';
export const BASE_URL_V2 = SERVER_URL + '/api/v2';
export const BASE_URL_V3 = SERVER_URL + '/api/v3';
export const BASE_WP_URL =
  (process.env.NODE_ENV === 'development'
    ? 'https://www.test-esign.com'
    : DOMAIN_URL) + '/wp-json/custom/v1';

export const HOME_PAGE_URL = 'http://www.test-esign.com/';

export const ELEMENTS_PER_PAGE = 10;

export const DROP_BOX_API_KEY = process.env.REACT_APP_DROP_BOX_API_KEY;

export const ONE_DRIVE_API_KEY = process.env.REACT_APP_ONE_DRIVE_API_KEY;

export const GOOGLE_DRIVE_API_KEY = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;
export const GOOGLE_DRIVE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
export const GOOGLE_DRIVE_APP_ID = process.env.REACT_APP_GOOGLE_DRIVE_APP_ID;

export const GOOGLE_AUTH_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;
export const APPLE_AUTH_CLIENT_ID = process.env.REACT_APP_APPLE_AUTH_CLIENT_ID;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
